import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import CountryModule from "@/store/modules/CountryModule";
import UserGroupModule from "@/store/modules/UserGroupModule";
import TrainingModule from "@/store/modules/TrainingModule";
import TrainingWeekModule from "@/store/modules/TrainingWeekModule";
import TrainingEventModule from "@/store/modules/TrainingEventModule";
import SpecializationModule from "@/store/modules/SpecializationModule";
import FileModule from "@/store/modules/FileModule";
import ToolModule from "@/store/modules/ToolModule";
import UserGameDataModule from "@/store/modules/UserGameDataModule";
import CompanyModule from "@/store/modules/CompanyModule";
import ProgramModule from "@/store/modules/ProgramModule";
import ModalModule from "@/store/modules/ModalModule";
import ChatMessageModule from "@/store/modules/ChatMessageModule";
import SensesBoardModule from "@/store/modules/SensesBoardModule";
import WoocommerceModule from "@/store/modules/WoocommerceModule";
import SportModule from "@/store/modules/SportModule";
import CoachModule from "@/store/modules/CoachModule";
import RegistrationTokenModule from "@/store/modules/RegistrationTokenModule";
import DiagnosticModule from "@/store/modules/DiagnosticModule";
import UserContactEventModule from "@/store/modules/UserContactEventModule";
import AccessoryModule from "@/store/modules/AccessoryModule";
import ExerciseModule from "@/store/modules/ExerciseModule";
import ExerciseCategoryModule from "@/store/modules/ExerciseCategoryModule";
import LanguageModule from "@/store/modules/LanguageModule";
import NotificationModule from "@/store/modules/NotificationsModule";
import CompanyBankAccountModule from "@/store/modules/CompanyBankAccountModule";
import InvoiceModule from "@/store/modules/InvoiceModule";
import BodyPartModule from "@/store/modules/BodyPartModule";
import UnitModule from "@/store/modules/UnitModule";
import ExerciseExecutionModule from "@/store/modules/ExerciseExecutionModule";
import PaymentModule from "@/store/modules/PaymentModule";
import TrainingTemplateModule from "@/store/modules/TrainingTemplateModule";
import TrainingTemplatePeriodModule from "@/store/modules/TrainingTemplatePeriodModule";
import TemplatePeriodExerciseModule from "@/store/modules/TemplatePeriodExerciseModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    CountryModule,
    UserGroupModule,
    TrainingModule,
    TrainingWeekModule,
    TrainingEventModule,
    SpecializationModule,
    FileModule,
    ToolModule,
    UserGameDataModule,
    CompanyModule,
    ProgramModule,
    ModalModule,
    ChatMessageModule,
    SensesBoardModule,
    WoocommerceModule,
    SportModule,
    CoachModule,
    RegistrationTokenModule,
    DiagnosticModule,
    UserContactEventModule,
    AccessoryModule,
    LanguageModule,
    NotificationModule,
    CompanyBankAccountModule,
    InvoiceModule,
    ExerciseModule,
    ExerciseCategoryModule,
    BodyPartModule,
    UnitModule,
    ExerciseExecutionModule,
    PaymentModule,
    TrainingTemplateModule,
    TrainingTemplatePeriodModule,
    TemplatePeriodExerciseModule,
  },
});

export default store;
