export const trainingTemplateEn = {
  trainingTemplates: {
    title: "Training templates",
    toolTips: {
      edit: "Edit training template {trainingTemplate}",
      delete: "Delete training template {trainingTemplate}",
    },
    editTitle: "Edit training template",
    newTitle: "New training template",
    addTrainingTemplate: "Add training template",

    errors: {
      notFound: "Training template {id} not found",
      notYourRole: "Cant access data for training template {id}",
      nameExists: "Training template named `{name}` already exists",
      commonGET_TRAINING_TEMPLATE_BY_ID:
        "Error retrieving training template data",
      commonDELETE_TRAINING_TEMPLATE_BY_ID: "Error deleting training template",
      commonDELETE_TRAINING_TEMPLATES: "Error deleting training templates",
      cantGetTrainingTemplates: "Failed to fetch training templates data",
      cantGetClientTrainingTemplates:
        "Failed to fetch client training templates data",
    },
    client: {
      playVideo: "Play video",
      title: "Training 2.0",
      titleClient: "My training",
      trainingPaused: "Training is paused",
      trainingReady: "Your training is ready",
      trainingNotPaid:
        "We are currently awaiting the payment to be credited to our account. Once the payment has been successfully processed, the product will be immediately available.",
      trainingAssigned: "Training is waiting to start",
      trainingStartAt: "Training is scheduled to begin on {date}.",
      startTraining: "Start training",
      pauseTraining: "Pause training",
      exerciseLength: "Exercise length",
      bodyPart: "Body part",
      exerciseExecution: "Exercise execution",
      accessories: "Accessories",
      noAccessories: "no accessories",
      description: "Exercise description",
      markAsFinished: "Not finished yet",
      markAsUnfinished: "Finished",
    },
    form: {
      addTrainingTemplate: "Add training template",
      name: "Name",
      description: "Desription",
      period: "Period of change",
      type: "Type",
      exerciseSet: "Exercise set",
      exercises_in_period: "Exercises / period",
      no_exercise_duplicities: "No exercise duplicities",
      status: "Status",
      client: "Client",
      assigned_at: "Assigned at",
      length: "Length",
      remaining_periods: "Remaining periods",
      company: "Companies",
      programs: "Programs",
      timeline: "Timeline",
      trainingTemplateChanged: "Training template was stored",
      trainingTemplateChangedFail: "Failed to store training template",
      trainingTemplateAdded: "New training template was created",
      trainingTemplateAddFail: "Failed to add new training template",
      newTrainingTemplateTimeline:
        "You must save the template to enter items in the timeline.",
    },
    exercise: {
      form: {
        unit: "Unit",
        unit_type: "Unit type",
        counting_type: "Counting",
        exercise_category: "Category",
        body_part: "Body part",
        exerciseSelection: "Exercise selection",
        parameters: "Parameters",
        value: "Value",
        exercise: "Exercise",
        exercise_execution: "Execution",
        tools: "Tools / Games",
        accessories: "Accessories",
      },
      modal: {
        templatePeriodExerciseTemplateAdded:
          "New exercise template was created",
        newItem: "New exercise",
        form: {
          name: "Name",
          required: {
            name: "Name is a required field",
          },
        },
      },
    },
    counting_types: {
      time_increment: "Increment",
      time_decrement: "Decrement",
      time_hold: "Hold",
      "activity-pause": "Activity/Pause",
    },
    periods: {
      editTitle: "Edit exercise set",
      newTitle: "Add exercise set",
      selector: {
        title: "Select exercise set",
      },
      toolTips: {
        edit: "Edit exercise set {trainingTemplatePeriod}",
        delete: "Delete exercise set {trainingTemplatePeriod}",
      },
      errors: {
        notFound: "Exercise set {id} not found",
        notYourRole: "Cant access data for exercise set {id}",
        nameExists: "Exercise set named `{name}` already exists",
        commonGET_TRAINING_TEMPLATE_PERIOD_BY_ID:
          "Error retrieving exercise set data",
        commonDELETE_TRAINING_TEMPLATE_PERIOD_BY_ID:
          "Error deleting exercise set",
        commonDELETE_TRAINING_TEMPLATE_PERIODS: "Error deleting exercise sets",
        cantGetTrainingTemplatePeriods: "Failed to fetch exercise set data",
      },
      addTrainingTemplatePeriod: "Add exercise set",
      countable: {
        day: "Nr. of days",
        week: "Nr. of weeks",
        month: "Nr. of months",
        year: "Nr. of years",
      },
      day: "day",
      week: "week",
      month: "month",
      year: "year",
      exerciseCount: "Exercise count",
      copy: "Copy",
      new: "New",
      actions: {
        edit: "Edit item",
        add: "Add item",
        addFromTemplate: "Add item from library",
        clone: "Clone item",
        delete: "Delete item",
      },
      table: {
        filter: "Filter",
        header: {
          name: "Name",
          exerciseCount: "Exercise count",
          actions: "Actions",
        },
        deleteTrainingTemplatePeriodQuestion:
          "Do you really want to delete exercise set <strong>{trainingTemplatePeriod}</strong>?",
        searchTrainingTemplatePeriods: "Search exercise set",
        trainingTemplatePeriodDeleted:
          "Exercise set {trainingTemplatePeriod} was deleted",
        trainingTemplatePeriodsDeleted: "Exercise sets were deleted",
        noRecords: "No exercise sets found",
      },
      form: {
        name: "Name",
        exercises: "Exercises",
        required: {
          name: "Name is a required field",
        },
        trainingTemplatePeriodChanged: "Exercise set was stored",
        trainingTemplatePeriodChangedFail: "Failed to store exercise set",
        trainingTemplatePeriodAdded: "New exercise set was created",
        trainingTemplatePeriodAddFail: "Failed to add new exercise set",
      },
      modal: {
        trainingTemplatePeriodTemplateAdded:
          "New exercise set template was created",
        newItem: "New item",
        form: {
          name: "Name",
          exercises: "Exercises",
          required: {
            name: "Name is a required field",
          },
        },
      },
    },
    exercises: {
      editTitle: "Edit exercise template",
      newTitle: "Add exercise template",
      cloneTitle: "Copy of exercise template",
      selector: {
        title: "Select exercise template",
      },
      toolTips: {
        edit: "Edit exercise template",
        delete: "Delete exercise template",
        clone: "Clone exercise template",
      },
      errors: {
        notFound: "Exercise template {id} not found",
        notYourRole: "Cant access data for exercise template {id}",
        nameExists: "Exercise template named `{name}` already exists",
        commonGET_TEMPLATE_PERIOD_EXERCISE_BY_ID:
          "Error retrieving exercise template data",
        commonDELETE_TEMPLATE_PERIOD_EXERCISE_BY_ID:
          "Error deleting exercise template",
        commonDELETE_TEMPLATE_PERIOD_EXERCISES:
          "Error deleting exercise templates",
        cantGetTemplatePeriodExercises:
          "Failed to fetch exercise template data",
      },
      addTemplatePeriodExercise: "Add exercise template",

      table: {
        filter: "Filter",
        header: {
          name: "Name",
          code: "Code",
          exercise: "Exercise",
          actions: "Actions",
        },
        deleteTemplatePeriodExerciseQuestion:
          "Do you really want to delete exercise template <strong>{templatePeriodExercise}</strong>?",
        searchTemplatePeriodExercises: "Search exercise template",
        templatePeriodExerciseDeleted:
          "Exercise template {templatePeriodExercise} was deleted",
        templatePeriodExercisesDeleted: "Exercise templates were deleted",
        noRecords: "No exercise templates found",
      },
      form: {
        name: "Name",
        exercises: "Exercises",
        required: {
          name: "Name is a required field",
        },
        templatePeriodExerciseChanged: "Exercise template was stored",
        templatePeriodExerciseChangedFail: "Failed to store exercise template",
        templatePeriodExerciseAdded: "New exercise template was created",
        templatePeriodExerciseAddFail: "Failed to add new exercise template",
      },
    },
    statuses: {
      draft: "Draft",
      template: "Template",
      assigned: "Assigned",
      paused: "Paused",
      finished: "Finished",
      active: "Active",
      waiting: "Waiting",
    },
    types: {
      auto: "Auto",
      manual: "Manual",
      random: "Random",
    },
    table: {
      filter: "Filter",
      header: {
        name: "Name",
        description: "Desription",
        period: "Period of change",
        type: "Type",
        status: "Status",
        client: "Client",
        assigned_at: "Assigned at",
        length: "Length",
        remaining_periods: "Remaining periods",
        company: "Companies",
        programs: "Programs",
        actions: "Actions",
      },
      deleteTrainingTemplateQuestion:
        "Do you really want to delete  training template <strong>{trainingTemplate}</strong>?",
      searchTrainingTemplates: "Search training templates",
      trainingTemplateDeleted:
        "TrainingTemplate {trainingTemplate} was deleted",
      trainingTemplatesDeleted: "Training templates were deleted",
      noRecords: "No training templates found",
    },
  },
};
