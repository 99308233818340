export const userCs = {
  users: {
    trainingInfo: "Informace k tréninku",
    fillInvoiceDetails: "Vyplnit údaje pro fakturací",
    fillInvoiceDetailsNote: "Prosíme o kontrolu a vyplnění fakturačních údajů.",
    toolTips: {
      loggedThisWeek: "Byl přihlášen tento týden?",
      programsStatus: "Stav programu",
      programPaidStatus: "Stav zaplacení programu",
      trainingsStatus: "Stav tréninku",
      roleChange: "Změna role pro uživatele {user}",
      edit: "Upravit uživatele {user}",
      delete: "Odstranit uživatele {user}",
      invite: "Odeslat pozvánku pro uživatele {user}",
      inviteAgain: "Opětovně odeslat pozvánku pro uživatele {user}",
      createInvoice: "Vystavit fakturu",
      privacyChanged:
        "Uživatel změnil nastavení soukromí. Změnu potvrdíte kliknutím na tuto ikonu",
    },
    roleChange: {
      modal: {
        title: "Změna role pro uživatele {user}",
      },
    },
    errors: {
      notFound: "Uživatel id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit uživatele id {id}",
      emailExists: "Uživatel s emailem `{email}` již existuje",
      commonGET_USER_BY_ID: "Chyba při získávání dat o uživateli",
      commonDELETE_USER_BY_ID: "Chyba při odstraňování uživatele",
      commonINVITE_USER: "Chyba při odesílání pozvánky pro uživatele",
      commonDELETE_USERS: "Nepodařilo se odstranit uživatele",
      commonCHECK_PRIVACY_CHANGE: "Nepodařilo se schválit změnu soukromí",
      commonGDPR_CONSENT:
        "Nepodařilo se zpracovat Váš souhlas. Zkuste prosím později",
      avatarUploadFailed: "Nepodařilo se nahrát obrázek avatara",
      cantGetUsers: "Chyba při získávání dat o uživatelích",
      cantGetClients: "Chyba při získávání dat o klientech",
      cantGetGuests: "Chyba při získávání dat o hostech",
      cantGetOverseers: "Chyba při získávání dat o odpovědných osobách",
      cantGetConversation: "Chyba při získávání zpráv chatu",
      cantAddMessages: "Nepodařilo se přidat zprávu",
      cantMarkMessages: "Nepodařilo se označit zprávy jako přečtené",
      cantSendBulkMessage: "Nepodařilo se odeslat hromadnou zprávu",
      cantGetSmsBalance: "Nepodařilo se získat informace o kreditu Sms služby",
      cantGetActiveUserPrograms:
        "Nepodařilo se získat aktivní programy uživatele",
    },
    relationships: {
      father: {
        title: "Otec",
      },
      mother: {
        title: "Matka",
      },
      employer: {
        title: "Zaměstnavatel",
      },
    },
    overseers: {
      title: "Odpovědné osoby",
      roles: {
        coordinator: { title: "Koordinátor" },
        owner: { title: "Vlastník" },
        recommender: { title: "Doporučitel" },
        guarantor: { title: "Garant" },
        coach: { title: "Trenér" },
      },
    },
    roles: {
      legalRepresentative: {
        title: "Zák. zástupce",
        shortcut: "ZZS",
      },
      admin: {
        title: "Administrátor",
        shortcut: "ADM",
      },
      therapist: {
        title: "Garant",
        shortcut: "GAR",
      },
      coach: {
        title: "Trenér",
        shortcut: "TRE",
      },
      dealer: {
        title: "Obchodník",
        shortcut: "OBK",
      },
      specialist: {
        title: "Koordinátor",
        shortcut: "SPC",
      },
      client: {
        title: "Klient",
        shortcut: "KLI",
      },
      guest: {
        title: "Host",
        shortcut: "HST",
      },
    },
    newUser: "Nový uživatel",
    newUserCreated: "Nový uživatel byl vytvořen",
    table: {
      addUser: "Přidat uživatele",
      header: {
        firstName: "Jméno",
        lastName: "Příjmení",
        newClient: "Nový klient",
        email: "Email",
        company: "Firma",
        phone: "Telefon",
        role: "Role uživatele",
        actions: "Akce",
      },
      deleteUserQuestion:
        "Opravdu chcete odstranit uživatele <strong>{user}</strong>?",
      inviteUserQuestion:
        "Opravdu chcete odeslat pozvánku pro uživatele <strong>{user}</strong>?",
      deleteUsersQuestion:
        "Opravdu chcete odstranit uživatele(<strong>{count}</strong>)?",
      searchUsers: "Hledat uživatele",
      userDeleted: "Uživatel {user} byl odstraněn",
      userInvited: "Pozvánka pro uživatele {user} byla odeslána",
      usersDeleted: "Uživatele byli odstraněni",
      selected: "Vybráno",
      deleteSelected: "Smazat vybrané",
      noRecords: "Nebyli nalezeni žádní uživatelé",
    },
    account: {
      myTrainings: {
        title: "Moje tréninky",
        week: "Týden",
        training: "Trénink",
        noWeekEvents: "Pro tento týden nemáte přiřazeny žádné tréninky",
        contactUs: "Kontaktujte nás pro další postup",
        oops_line1: "Nezobrazují se Vám tréninky",
        oops_line2:
          "Zkontrolujte si, prosím, své platby a jestli je vše v pořádku, kontaktujte nás.",
        upcomingEvents:
          "Čeká na Vás trénink <strong class='text-primary'>{name}</strong>, který začne <strong class='text-primary'>{start}</strong>.",
        eventPaused: "Trénink je pozastavený. Bude pokračovat od {date}.",
        note: "Napište nám ... ",
      },
      loginHistory: {
        modal: {
          title: "Historie přihlášení",
          total: "Celkem",
          last30: "Posledních 30 přihlášení",
        },
      },
      lastLogin: "Naposledy přihlášen",
      lastContacted: "Naposledy kontaktován",
      lastActivity: "Naposledy aktivní",
      badges: {
        training: "Trénink",
      },
      overview: {
        title: "Detail účtu",
        editButton: "Upravit účet",
        form: {
          fullName: "Jméno a příjmení",
          contactPhone: "Kontaktní telefon",
          secondaryPhone: "Další telefon",
          address: "Adresa pro doručení pomůcek",
          age: "Věk",
          invAddress: "Fakturační adresa",
        },
      },
      otherContacts: {
        title: "Další kontakty",
        addButton: "Přidat kontakt",
        noContacts: "Nejsou zadány žádné další kontakty",
        missingLegalRepresentative:
          "Mezi dalšími kontakty musíte vytvořit osobu zákoného zástupce (otec / matka)",
        modal: {
          titleAdd: "Nový kontakt",
          titleUpdate: "Upravit kontakt `{name}`",
          form: {
            relationship: "Vztah",
            name: "Jméno",
            phone: "Telefon",
            email: "Email",
            sameEmailAsClient: "Email nesmí být stejný jako email klienta",
          },
        },
      },
      assignedTrainings: {
        title: "Přidělené tréninky",
        addButton: "Přidělit nový",
        noEvents: "Momentálně nemá uživatel přiřazeny žádné tréninky",
        deleteTrainingEvent:
          "Opravdu chcete odstranit trénink<br/><strong>{name}</strong> ?",
        states: {
          created: "Čekající na začátek",
          active: "Aktivní",
          paused: "Pozastavený",
          finished: "Dokončený",
        },
        until: "do",
        modal: {
          titleAdd: "Přiřadit nový trénink",
          titleUpdate: "Upravit trénink `{name}`",
          form: {
            startAt: "Začátek od",
            training: "Trénink",
            paused: "Pozastaveno",
            pausedUntil: "Pozastaveno do",
            continueWithWeek: "Pokračovat týdnem",
          },
        },
      },
      training: {
        title: "Trénink",
        form: {
          training: "Trénink Ano / Ne",
        },
      },
      chat: {
        title: "Chat",
        send: "Odeslat",
        balance: "Kredit SMS služby",
        typeMessage: "Napište zprávu",
        bulkMessageSent: "Hromadná zpráva byla odeslána.",
        form: {
          filterByTraining: "Pouze klienti v tréninku",
          company: "Firmy",
          userGroup: "Skupiny",
          clients: "Klienti",
          reciever: "Příjemce",
          twoFiveRecievers: "Příjemci",
          recievers: "Příjemců",
          selectCompany: "Vyberte firmu",
          selectGroup: "Vyberte skupinu",
          emailSubject: "Předmět",
          emailTitle: "Nadpis",
          otherEmails: "Další emaily",
          otherPhones: "Další telefoní čísla",
          emailTemplate: "Šablona",
        },
      },
      diagnostics: {
        title: "Diagnostika",
        form: {
          note: "Poznámka",
        },
      },
      privacy: {
        title: "Soukromí",
        form: {},
      },
      notifications: {
        title: "Připomínky",
        form: {},
      },
      programs: {
        title: "Produkty",
        finished: "Dokončené",
        active: "Aktivní",
        addButton: "Přidělit nový",
        noPrograms: "Momentálně nemá uživatel přiřazeny žádné produkty",
        deleteUserProgram:
          "Opravdu chcete odstranit produkt<br/><strong>{name}</strong> ?",
        modal: {
          titleAdd: "Přiřadit nový produkt",
          titleUpdate: "Upravit produkt `{name}`",
          form: {
            startAt: "Začátek od",
            program: "Produkt",
            invoice_sent: "FA odeslána",
            invoice_paid: "Zaplaceno",
            training_template: "Šablona tréninku",
            start_by_client: "Spuštění klientem",
            activate_training: "Aktivovat trénink",
            price: "Cena",
            currency: "Měna",
            nr_of_months: "Počet měsíců",
            eshop_product: "Program na eshopu",
            noEshopProduct:
              "K produktu není přířazen žádný aktivní produkt na eshopu",
            activateTheseTrainings: "Aktivuje tyto tréninky",
          },
        },
      },
      results: {
        title: "Výsledky",
        lastMeasurement: "Aktuální měření",
        createdBy: "Měření provedl",
        prevLastMeasurement: "Předchozí měření",
        initialMeasurement: "Vstupní měření",
        form: {
          description: "Popis",
          userChanged: "Uživatel byl upraven!",
          userChangedFail: "Nepodařilo se upravit uživatele!",
          sendToLegalRepresentative: "Odeslat zákonému zástupci",
          sendToClient: "Odeslat klientovi",
          download: "Stáhnout",
          generatePdf: "Vygenerovat Pdf a ",
          pdfGenerateFailed: "Nepodařilo se vygenerovat Pdf",
          pdfGenerated: "Pdf s výsledky je připraveno ke stažení",
          pdfMailed: "Pdf s výsledky bylo odesláno",
        },
        noRecords: "Nebyli nalezeny žádné výsledky",
      },
      invoices: {
        title: "Faktury vydané",
        noRecords: "Nebyli nalezeny žádné faktury",
      },
      assignment: {
        title: "Zařazení",
        form: {
          userGroup: "Skupina",
          company: "Firma",
          specializations: "Specializace",
          diagnostics: "Diagnostiky",
          sports: "Sport",
          specialists: "Přiřazení koordinátoři",
          specialist_groups: "Skupiny koordinátora",
          specialist_groups_as: "Jako koordinator pro",
          role: "Role",
          userChanged: "Uživatel byl upraven!",
          userChangedFail: "Nepodařilo se upravit uživatele!",
        },
      },
      assignedClients: {
        title: "Přiřazení klienti",
        form: {
          company: "Firmy",
          userGroup: "Skupiny",
          clients: "Klienti",
          selectCompany: "Vyberte firmu",
          selectGroup: "Vyberte skupinu",
        },
      },
      settings: {
        title: "Nastavení účtu",
        form: {
          basicInfo: "Základní informace",
          invoiceCompany: "Fakturovat za firmu",
          address: "Adresa pro doručení pomůcek",
          avatar: "Avatar",
          employment: "Zaměstnání",
          specialists: "Přiřazení koordinátoři",
          changeAvatar: "Změnit avatara",
          removeAvatar: "Odstranit avatara",
          allowedFileTypes: "Povolené typy obrázků",
          fullName: "Jméno a příjmení",
          firstName: "Jméno",
          lastName: "Příjmení",
          contactPhone: "Kontaktní telefon",
          secondaryPhone: "Další telefon",
          email: "Email",
          language: "Jazyk tréninku",
          email_legal_representative: "Email zákonného zástupce",
          password: "Heslo",
          confirmPassword: "Heslo znovu",
          country: "Země",
          street: "Ulice",
          city: "Obec",
          zip: "PSČ",
          birthDate: "Rok narození",
          pickADay: "Vyberte den",
          pickAYear: "Vyberte rok",
          userChanged: "Uživatel byl upraven!",
          userChangedFail: "Nepodařilo se upravit uživatele!",
          isCompany: "Právnická osoba",
          invoiceDetails: "Fakturační údaje",
          in: "IČ",
          role: "Role",
          tin: "DIČ",
          companyName: "Název společnosti",
        },
        signInMethod: {
          title: "Přihlašovací údaje",
          email: {
            title: "Email",
            enterNewEmailAddress: "Zadejte nový email",
            confirmPassword: "Zadejte heslo",
            updateEmail: "Uložit Email",
            changeEmail: "Změnit Email",
            emailChanged: "Email byl úspěšně změněn!",
            emailChangedFail: "Nepodařilo se změnit email!",
            passwordFail: "Chybné heslo!",
          },
          password: {
            title: "Heslo",
            currentPassword: "Staré heslo",
            newPassword: "Nové heslo",
            confirmNewPassword: "Nové heslo znovu",
            updatePassword: "Uložit Heslo",
            resetPassword: "Změnit Heslo",
            passwordChanged: "Heslo bylo úspěšně změněno!",
            passwordChangedFail: "Nepodařilo se změnit heslo!",
            passwordFail: "Chybné heslo!",
          },
        },
      },
    },
  },
};
