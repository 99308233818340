export const trainingTemplateCs = {
  trainingTemplates: {
    title: "Šablony tréninků",
    toolTips: {
      edit: "Upravit šablonu tréninku {trainingTemplate}",
      delete: "Odstranit šablonu tréninku {trainingTemplate}",
    },
    editTitle: "Upravit šablonu tréninku",
    newTitle: "Nová šablona tréninku",
    addTrainingTemplate: "Přidat šablonu tréninku",
    errors: {
      notFound: "Šablona tréninku id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit šablonu tréninku id {id}",
      nameExists: "Název šablony `{name}` již existuje",
      commonGET_TRAINING_TEMPLATE_BY_ID:
        "Chyba při získávání dat o šablonách tréninku",
      commonDELETE_TRAINING_TEMPLATE_BY_ID:
        "Chyba při odstraňování šablony tréninku",
      commonDELETE_TRAINING_TEMPLATES:
        "Nepodařilo se odstranit šablonu tréninku",
      cantGetTrainingTemplates: "Chyba při získávání dat o šablonách tréninku",
      cantGetClientTrainingTemplates:
        "Chyba při získávání dat o šablonách tréninku pro klienta",
    },
    client: {
      playVideo: "Přehrát video",
      title: "Trénink 2.0",
      titleClient: "Můj trénink",
      trainingPaused: "Trénink je pozastavený",
      trainingReady: "Váš trénink je připravený",
      trainingNotPaid:
        "V současné době čekáme na připsání platby na náš účet. Jakmile bude platba úspěšně zpracována, produkt bude okamžitě k dispozici.",
      trainingAssigned: "Trénink čeká na spuštění",
      trainingStartAt: "Začátek tréninku je plánován na {date}.",
      startTraining: "Spustit trénink",
      pauseTraining: "Pozastavit trénink",
      exerciseLength: "Délka cvičení",
      bodyPart: "Cvičí",
      exerciseExecution: "Způsob provádění",
      accessories: "Pomůcky",
      noAccessories: "bez pomůcek",
      description: "Popis cvičení",
      markAsFinished: "Neodcvičeno",
      markAsUnfinished: "Odcvičeno",
    },
    form: {
      addTrainingTemplate: "Přidat šablonu tréninku",
      name: "Název",
      description: "Popis",
      period: "Jednotka změny",
      type: "Typ",
      status: "Stav",
      exerciseSet: "Sada cviků",
      exercises_in_period: "Počet cviků / období",
      no_exercise_duplicities: "Žádné duplicitní cviky",
      client: "Klient",
      assigned_at: "Přiřazeno",
      length: "Délka",
      remaining_periods: "Zbývá jednotek",
      company: "Firmy",
      programs: "Produkty",
      timeline: "Časová osa",
      trainingTemplateChanged: "Šablona tréninku byla upravena",
      trainingTemplateChangedFail: "Nepodařilo se upravit šablonu tréninku",
      trainingTemplateAdded: "Nová šablona tréninku byla vytvořena",
      trainingTemplateAddFail: "Nepodařilo se vytvořit novou šablonu tréninku",
      newTrainingTemplateTimeline:
        "Pro zadávání položek do časové osy musíte šablonu uložit.",
    },
    exercise: {
      form: {
        unit: "Jednotka",
        unit_type: "Druh jednotky",
        body_part: "Cvičí",
        exercise_category: "Kategorie",
        counting_type: "Počítání",
        exerciseSelection: "Výběr cviku",
        parameters: "Parametry",
        value: "Hodnota",
        exercise: "Cvik",
        exercise_execution: "Provádění",
        tools: "Nástoje / hry",
        accessories: "Pomůcky",
      },
      modal: {
        templatePeriodExerciseTemplateAdded:
          "Nová šablona cviku byla vytvořena",
        newItem: "Nový cvik",
        form: {
          name: "Název",
          required: {
            name: "Název šablony nesmí být prázdný",
          },
        },
      },
    },
    counting_types: {
      time_increment: "Přičítání",
      time_decrement: "Odečítání",
      time_hold: "Výdrž",
      "activity-pause": "Aktivita/Pauza",
    },
    periods: {
      editTitle: "Upravit sadu cviků",
      newTitle: "Nová sada cviků",
      selector: {
        title: "Vyberte sadu cviků",
      },
      toolTips: {
        edit: "Upravit sadu cviků {trainingTemplatePeriod}",
        delete: "Odstranit sadu cviků {trainingTemplatePeriod}",
      },

      errors: {
        notFound: "Sada cviků id {id} nebyla nalezena",
        notYourRole: "Nemáte práva zobrazit sadu cviků id {id}",
        nameExists: "Název sady cviků `{name}` již existuje",
        commonGET_TRAINING_TEMPLATE_PERIOD_BY_ID:
          "Chyba při získávání dat o sadě cviků",
        commonDELETE_TRAINING_TEMPLATE_PERIOD_BY_ID:
          "Chyba při odstraňování sady cviků",
        commonDELETE_TRAINING_TEMPLATE_PERIODS:
          "Nepodařilo se odstranit sadu cviků",
        cantGetTrainingTemplatePeriods:
          "Chyba při získávání dat o sadách cviků",
      },
      addTrainingTemplatePeriod: "Přidat sadu cviků",
      countable: {
        day: "Počet dnů",
        week: "Počet týdnů",
        month: "Počet měsíců",
        year: "Počet roků",
      },
      day: "den",
      week: "týden",
      month: "měsíc",
      year: "rok",
      exerciseCount: "Počet cviků",
      copy: "Kopie",
      new: "Nový",
      actions: {
        edit: "Upravit položku",
        add: "Přidat položku",
        addFromTemplate: "Přidat položku z knihovny",
        clone: "Duplikovat položku",
        delete: "Odstranit položku",
      },
      table: {
        filter: "Filtr",
        header: {
          name: "Název",
          exerciseCount: "Počet cviků",
          actions: "Akce",
        },
        deleteTrainingTemplatePeriodQuestion:
          "Opravdu chcete odstranit sadu cviků <strong>{trainingTemplatePeriod}</strong>?",
        searchTrainingTemplatePeriods: "Hledat sadu cviků",
        trainingTemplatePeriodDeleted:
          "Sada cviků {trainingTemplatePeriod} byla odstraněna",
        trainingTemplatePeriodsDeleted: "Sady cviků byly odstraněny",
        noRecords: "Nebyly nalezeny žádné sady cviků",
      },
      form: {
        name: "Název",
        exercises: "Cviky",
        required: {
          name: "Název sady nesmí být prázdný",
        },
        trainingTemplatePeriodChanged: "Sada cviků byla upravena",
        trainingTemplatePeriodChangedFail: "Nepodařilo se upravit sadu cviků",
        trainingTemplatePeriodAdded: "Nová sada cviků byla vytvořena",
        trainingTemplatePeriodAddFail:
          "Nepodařilo se vytvořit novou sadu cviků",
      },
      modal: {
        newItem: "Nová položka",
        trainingTemplatePeriodTemplateAdded:
          "Nová šablona sady cviků byla vytvořena",
        form: {
          name: "Název",
          exercises: "Cviky",
          required: {
            name: "Název sady nesmí být prázdný",
          },
        },
      },
    },
    exercises: {
      editTitle: "Upravit šablonu cviku",
      newTitle: "Nová šablona cviku",
      cloneTitle: "Kopie šablony cviku",
      selector: {
        title: "Vyberte šablonu cviku",
      },
      toolTips: {
        edit: "Upravit šablonu cviku",
        delete: "Odstranit šablonu cviku",
        clone: "Duplikovat šablonu cviku",
      },

      errors: {
        notFound: "Šablona cviku id {id} nebyla nalezena",
        notYourRole: "Nemáte práva zobrazit šablonu cviku id {id}",
        nameExists: "Šablona cviku`{name}` již existuje",
        commonGET_TEMPLATE_PERIOD_EXERCISE_BY_ID:
          "Chyba při získávání dat o šabloně cviku",
        commonDELETE_TEMPLATE_PERIOD_EXERCISE_BY_ID:
          "Chyba při odstraňování šablony cviku",
        commonDELETE_TEMPLATE_PERIOD_EXERCISES:
          "Nepodařilo se odstranit šablonu cviku",
        cantGetTemplatePeriodExercises:
          "Chyba při získávání dat o šablonách cviku",
      },
      addTemplatePeriodExercise: "Přidat šablonu cviku",
      table: {
        filter: "Filtr",
        header: {
          name: "Název",
          code: "Kód",
          exercise: "Cvik",
          actions: "Akce",
        },
        deleteTemplatePeriodExerciseQuestion:
          "Opravdu chcete odstranit šablonu cviku <strong>{templatePeriodExercise}</strong>?",
        searchTemplatePeriodExercises: "Hledat šablonu cviku",
        templatePeriodExerciseDeleted:
          "Šablona cviku {templatePeriodExercise} byla odstraněna",
        templatePeriodExercisesDeleted: "Šablony cviku byly odstraněny",
        noRecords: "Nebyly nalezeny žádné šablony cviku",
      },
      form: {
        name: "Název",
        required: {
          name: "Název šablony nesmí být prázdný",
        },
        templatePeriodExerciseChanged: "Šablona cviku byla upravena",
        templatePeriodExerciseChangedFail:
          "Nepodařilo se upravit šablonu cviku",
        templatePeriodExerciseAdded: "Nová šablona cviku byla vytvořena",
        templatePeriodExerciseAddFail:
          "Nepodařilo se vytvořit novou šablonu cviku",
      },
    },
    statuses: {
      draft: "Rozpracováno",
      template: "Šablona",
      assigned: "Přiřazeno",
      paused: "Pozastaveno",
      finished: "Ukončeno",
      active: "Aktivní",
      waiting: "Ve frontě",
    },
    types: {
      auto: "Auto",
      manual: "Manual",
      random: "Náhodný výběr",
    },
    table: {
      filter: "Filtr",
      header: {
        name: "Název",
        description: "Popis",
        period: "Jednotka změny",
        type: "Typ",
        status: "Stav",
        client: "Klient",
        assigned_at: "Přiřazeno",
        length: "Délka",
        remaining_periods: "Zbývá jednotek",
        company: "Firmy",
        programs: "Produkty",
        actions: "Akce",
      },
      deleteTrainingTemplateQuestion:
        "Opravdu chcete odstranit šablonu tréninku <strong>{trainingTemplate}</strong>?",
      searchTrainingTemplates: "Hledat šablonu tréninku",
      trainingTemplateDeleted:
        "Šablona tréninku {trainingTemplate} byla odstraněna",
      trainingTemplatesDeleted: "Šablony tréninku byly odstraněny",
      noRecords: "Nebyly nalezeny žádné šablony tréninku",
    },
  },
};
