export const userEn = {
  users: {
    trainingInfo: "Training info",
    fillInvoiceDetails: "Fill invoice details",
    fillInvoiceDetailsNote: "Please check and fill in the invoice details.",
    toolTips: {
      loggedThisWeek: "Was logged this week?",
      programsStatus: "Program status",
      programPaidStatus: "Program payment status",
      trainingsStatus: "Training status",
      roleChange: "Role change for user {user}",
      edit: "Edit user {user}",
      delete: "Delete user {user}",
      invite: "Invite user {user}",
      inviteAgain: "Invite again user {user}",
      createInvoice: "Issue invoice",
      privacyChanged:
        "User has changed privacy settings. Click on this icon to approve new settings",
    },
    roleChange: {
      modal: {
        title: "Role change for user {user}",
      },
    },
    errors: {
      notFound: "User {id} not found",
      notYourRole: "Cant access data for user {id}",
      emailExists: "User with email `{email}` already exists",
      commonGET_USER_BY_ID: "Error retrieving user data",
      commonDELETE_USER_BY_ID: "Error deleting user",
      commonINVITE_USER: "Error sending invitation to user",
      commonDELETE_USERS: "Error deleting users",
      commonCHECK_PRIVACY_CHANGE: "Failed to approve privacy settings change",
      commonGDPR_CONSENT:
        "Failed to process you consent. Please try again latter",
      avatarUploadFailed: "Failed to upload avatar image",
      cantGetUsers: "Failed to fetch users data",
      cantGetClients: "Failed to fetch clients data",
      cantGetGuests: "Failed to fetch guests data",
      cantGetOverseers: "Failed to fetch overseers data",
      cantGetConversation: "Failed to load chat messages",
      cantAddMessages: "Failed to submit message",
      cantMarkMessages: "Failed to mark messages as read",
      cantSendBulkMessage: "Failed to send bulk message",
      cantGetSmsBalance: "Failed to get Sms API balance",
      cantGetActiveUserPrograms: "Failed to get user active programs",
    },
    relationships: {
      father: {
        title: "Father",
      },
      mother: {
        title: "Mother",
      },
      employer: {
        title: "Employer",
      },
    },
    overseers: {
      title: "Overseers",
      roles: {
        coordinator: { title: "Coordinator" },
        owner: { title: "Owner" },
        recommender: { title: "Recommender" },
        guarantor: { title: "Guarantor" },
        coach: { title: "Coach" },
      },
    },
    roles: {
      legalRepresentative: {
        title: "Leg. representative",
        shortcut: "LRE",
      },
      admin: {
        title: "Administrator",
        shortcut: "ADM",
      },
      therapist: {
        title: "Garant",
        shortcut: "GAR",
      },
      coach: {
        title: "Coach",
        shortcut: "COA",
      },
      dealer: {
        title: "Dealer",
        shortcut: "DEA",
      },
      specialist: {
        title: "Coordinator",
        shortcut: "COR",
      },
      client: {
        title: "Client",
        shortcut: "CLI",
      },
      guest: {
        title: "Guest",
        shortcut: "GST",
      },
    },
    newUser: "New user",
    newUserCreated: "New user was created",
    table: {
      addUser: "Add user",
      header: {
        firstName: "First name",
        lastName: "Last name",
        newClient: "New client",
        email: "Email",
        company: "Company",
        phone: "Phone",
        role: "User role",
        actions: "Actions",
      },
      deleteUserQuestion:
        "Do you really want to delete user <strong>{user}</strong>?",
      inviteUserQuestion:
        "Do you really want to invite user <strong>{user}</strong>?",
      deleteUsersQuestion:
        "Do you really want to delete users(<strong>{count}</strong>)?",
      searchUsers: "Search users",
      userDeleted: "User {user} was deleted",
      userInvited: "Invitation for user {user} was sent",
      usersDeleted: "Users were deleted",
      selected: "Selected",
      deleteSelected: "Delete Selected",
      noRecords: "No users found",
    },
    account: {
      myTrainings: {
        title: "My trainings",
        week: "Week",
        training: "Training",
        noWeekEvents: "No trainings assigned for this week",
        contactUs: "Contact us for further action",
        oops_line1: "Trainings are not displayed",
        oops_line2:
          "Please check your payments and contact us if everything is in order.",
        upcomingEvents:
          "There is <strong class='text-primary'>{name}</strong> training, starting on <strong class='text-primary'>{start}</strong>.",
        eventPaused: "Training is paused. It will continue starting on {date}.",
        note: "Place for your note ... ",
      },
      loginHistory: {
        modal: {
          title: "LogIn History",
          total: "Total",
          last30: "Last 30 logins",
        },
      },
      lastLogin: "Last login at",
      lastContacted: "Lastcontacted at",
      lastActivity: "Last activity at",
      badges: {
        training: "Training",
      },
      overview: {
        title: "Account Details",
        editButton: "Edit Profile",
        form: {
          fullName: "Full Name",
          contactPhone: "Contact Phone",
          secondaryPhone: "Secondary Phone",
          address: "Address for delivery of aids",
          age: "Age",
          invAddress: "Invoice address",
        },
      },
      otherContacts: {
        title: "Other contacts",
        addButton: "Add contact",
        noContacts: "No other contacts created",
        missingLegalRepresentative:
          "Among other contacts, you must create the person of the legal representative (father / mother)",
        modal: {
          titleAdd: "New contact",
          titleUpdate: "Edit contact `{name}`",
          form: {
            relationship: "Relationship",
            name: "Name",
            phone: "Phone",
            email: "Email",
            sameEmailAsClient:
              "The email must not be the same as the client's email",
          },
        },
      },
      assignedTrainings: {
        title: "Assigned trainings",
        addButton: "Assign new",
        noEvents: "Currently the user has no trainings assigned",
        deleteTrainingEvent:
          "Do you really want to delete training<br/><strong>{name}</strong> ?",
        states: {
          created: "Waiting for start",
          active: "Active",
          paused: "Paused",
          finished: "Finished",
        },
        until: "until",
        modal: {
          titleAdd: "Assign new training",
          titleUpdate: "Edit training `{name}`",
          form: {
            startAt: "Starts at",
            training: "Training",
            paused: "Paused",
            pausedUntil: "Paused until",
            continueWithWeek: "Continue with week",
          },
        },
      },
      training: {
        title: "Training",
        form: {
          training: "Training Yes / No",
        },
      },
      chat: {
        title: "Chat",
        send: "Send",
        balance: "SMS API balance",
        typeMessage: "Type message",
        bulkMessageSent: "Bulk message sent.",
        form: {
          filterByTraining: "Only clients in training",
          company: "Companies",
          userGroup: "User groups",
          clients: "Clients",
          reciever: "Reciever",
          twoFiveRecievers: "Recievers",
          recievers: "Recievers",
          selectCompany: "Select a company",
          selectGroup: "Select a group",
          emailSubject: "Subject",
          emailTitle: "Title",
          otherEmails: "Other emails",
          otherPhones: "Other phones",
          emailTemplate: "Template",
        },
      },
      diagnostics: {
        title: "Diagnostics",
        form: {
          note: "Note",
        },
      },
      privacy: {
        title: "Privacy",
        form: {},
      },
      notifications: {
        title: "Notifications",
        form: {},
      },
      programs: {
        title: "Programs",
        finished: "Finished",
        active: "Active",
        addButton: "Assign new",
        noPrograms: "Currently the user has no programs assigned",
        deleteUserProgram:
          "Do you really want to delete product<br/><strong>{name}</strong> ?",
        modal: {
          titleAdd: "Assign new product",
          titleUpdate: "Edit product `{name}`",
          form: {
            startAt: "Starts at",
            program: "Product",
            invoice_sent: "Invoice sent",
            invoice_paid: "Invoice paid",
            training_template: "Training template",
            start_by_client: "Start by client",
            activate_training: "Activate training",
            price: "Price",
            currency: "Currency",
            nr_of_months: "Nr of months",
            eshop_product: "Eshop product",
            noEshopProduct:
              "There is no active product on the eshop associated with the product",
            activateTheseTrainings: "Activate these trainings",
          },
        },
      },
      assignment: {
        title: "Assignment",
        form: {
          userGroup: "User group",
          company: "Company",
          specializations: "Specializations",
          diagnostics: "Diagnostics",
          specialists: "Assigned coordinators",
          sports: "Sports",
          specialist_groups: "Coordinators groups",
          specialist_groups_as: "As coordinator for",
          role: "Role",
          userChanged: "User was successfully saved!",
          userChangedFail: "Failed to save user!",
        },
      },
      assignedClients: {
        title: "Assigned clients",
        form: {
          company: "Companies",
          userGroup: "User groups",
          clients: "Clients",
          selectCompany: "Select a company",
          selectGroup: "Select a group",
        },
      },
      results: {
        title: "Results",
        lastMeasurement: "Latest measurement",
        createdBy: "Measured by",
        prevLastMeasurement: "Previous measurement",
        initialMeasurement: "Initial measurement",
        form: {
          description: "Description",
          userChanged: "User was successfully saved!",
          userChangedFail: "Failed to save user!",
          sendToLegalRepresentative: "Send to the legal representative",
          sendToClient: "Send to client",
          download: "Download",
          generatePdf: "Generate Pdf and",
          pdfGenerateFailed: "Failed to generate Pdf",
          pdfGenerated: "Results Pdf is ready for download",
          pdfMailed: "Results Pdf was mailed",
        },
        noRecords: "No results found",
      },
      invoices: {
        title: "Issued invoices",
        noRecords: "No invoices found",
      },
      settings: {
        title: "Account Settings",
        form: {
          basicInfo: "Basic information",
          address: "Address for delivery of aids",
          invoiceCompany: "Invoice on behalf of",
          avatar: "Avatar",
          employment: "Employment",
          changeAvatar: "Change avatar",
          specialists: "Assigned coordinators",
          removeAvatar: "Remove avatar",
          allowedFileTypes: "Allowed file types",
          fullName: "Full Name",
          firstName: "First name",
          lastName: "Last name",
          contactPhone: "Contact Phone",
          secondaryPhone: "Secondary Phone",
          email: "Email",
          language: "Training language",
          email_legal_representative: "Email of legal representative",
          password: "Password",
          confirmPassword: "Confirm Password",
          country: "Country",
          street: "Street",
          city: "City",
          zip: "Zip code",
          role: "Role",
          birthDate: "Year of birth",
          pickAYear: "Pick a year",
          pickADay: "Pick a day",
          userChanged: "User was successfully saved!",
          userChangedFail: "Failed to save user!",
          isCompany: "Is company",
          invoiceDetails: "Invoice details",
          in: "Company registration Id",
          tin: "Tax Id",
          companyName: "Company name",
        },
        signInMethod: {
          title: "Sign-in Method",
          email: {
            title: "Email Address",
            enterNewEmailAddress: "Enter New Email Address",
            confirmPassword: "Confirm Password",
            updateEmail: "Update Email",
            changeEmail: "Change Email",
            emailChanged: "Email is successfully changed!",
            emailChangedFail: "Failed to change email!",
            passwordFail: "Password is incorrect!",
          },
          password: {
            title: "Password",
            currentPassword: "Current Password",
            newPassword: "New Password",
            confirmNewPassword: "Confirm New Password",
            updatePassword: "Update Password",
            resetPassword: "Reset Password",
            passwordChanged: "Password is successfully changed!",
            passwordChangedFail: "Failed to change password!",
            passwordFail: "Password is incorrect!",
          },
        },
      },
    },
  },
};
